/* eslint-disable */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Box, Tabs, Tab, Typography, Button, CircularProgress} from "@mui/material";
import { updatesStyle } from "./Modal/style";
import { message } from 'antd';
import CloseIcon from "@mui/icons-material/Close";
import styles from "./style.module.css";
import getMachineType from "../../API/CentralizedCapsuleApis/getMachineType";
import syncCentralizedOD from "../../API/CentralizedCapsuleApis/syncCentralizedOD";
import syncLastDate from "../../API/CentralizedCapsuleApis/syncLastDate";
import getSyncJobStatus from "../../API/CentralizedCapsuleApis/getSyncJobStatus";
import { FaEye, FaPlusSquare } from "react-icons/fa";
import TableData from "./ViewTable/TableData";
import AddModal from "./Modal/AddModal"
import { setSyncLoadingStatus, setSyncJobRunId } from "../../redux/slices/odCentralizedSlice";
import PropTypes from "prop-types";
import SyncLogPopup from "./SyncLogPopup/index.jsx";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CapsuleParamTabs() {
  const [value, setValue] = useState(0);
  const dispatch = useDispatch();
  const loaderStatus = useSelector((state) => state?.centralizedOD?.SYNCAPI_LOADING_STATUS);
  const JOB_RUN_ID = useSelector((state) => state?.centralizedOD?.SYNCAPI_JOBRUN_ID);
  const [syncDate, setSyncDate] = useState("");
  const [AddModalStatus, setAddModalStatus] = useState(false);
  const [ModalHeader, setModalHeader] = useState(null);
  const [selectMachineType, setMachineType] = useState([]);
  const [selectTab, setTab] = useState(null);
  const [isSyncLogPopup,setIsSyncLogPopup] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTab(selectMachineType[newValue])
  };
  useEffect(() => {
    getMachineType().then((response) => {
      let data = response?.data?.data
      if (data.length) {
        // setMachineType(productTypeSorting(data, 'MachineType'))
      }
      setMachineType(data);
      setAddModalStatus(false)
    }).catch(err => message.error(err?.data?.error?.message));
    syncLastDateODCentralized();
  }, []);
  // This useEffect watches for changes in the 'value' state and can be used to trigger actions when the selected tab changes.
  useEffect(() => { }, [selectTab]);
  useEffect(() => { setTab(selectMachineType[value]) }, [selectMachineType])

  const syncODCentralized = async () => {
    try {
      const res = await syncCentralizedOD();
      if (res?.status === 1) {
        dispatch(setSyncLoadingStatus(true));
        dispatch(setSyncJobRunId(res?.data?.run_id));
      } else {
        dispatch(setSyncLoadingStatus(false));
        dispatch(setSyncJobRunId(''));
      }
    } catch (err) {
      message.error(err?.message);
    }
  };

  const syncLastDateODCentralized = async () => {
    const res = await syncLastDate();
    if (res?.status === 1) {
      setSyncDate(res?.data?.LastSyncOn)
    }
  };
  useEffect(() => {
    if (!JOB_RUN_ID) return;

    let attemptCount = 0;
    const MAX_ATTEMPTS = 10;

    const pollingInterval = setInterval(async () => {
      attemptCount++;
      console.log(`Checking job status (Attempt ${attemptCount})...`);
      try {
        const res = await getSyncJobStatus(JOB_RUN_ID);

        if (res?.status === 1 || attemptCount >= MAX_ATTEMPTS) {
          dispatch(setSyncLoadingStatus(false));
          dispatch(setSyncJobRunId(''));
          clearInterval(pollingInterval);
          await syncLastDateODCentralized();
          if (res?.status !== 1) {
            message.error(res?.message);
            return;
          }
          message.success(res?.message);
        } else {
          dispatch(setSyncLoadingStatus(true));
        }
      } catch (err) {
        dispatch(setSyncLoadingStatus(false));
        dispatch(setSyncJobRunId(''));
        clearInterval(pollingInterval);
        await syncLastDateODCentralized();
        message.error('Something went wrong');
      }

    }, 60000);

    return () => clearInterval(pollingInterval); // Cleanup
  }, [JOB_RUN_ID]);  // Runs when JOB_RUN_ID updates

  return (
    <Box sx={{ width: "100%" }}>
      <Box className={styles.cp_container}>
        <div className={styles.cp_left_box}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {selectMachineType.map((label, index) => (
              <Tab
                key={index}
                label={label.MachineType}
                {...a11yProps(index)} // Assuming a11yProps is defined elsewhere
                className={styles.tabs_head}
                sx={{
                  color: "#E0E0E0",
                  textTransform: "none",
                  fontWeight: 600,
                  fontSize: "13px",
                  fontFamily: "Montserrat, Helvetica, sans-serif"
                }}
              />
            ))}
          </Tabs>
        </div>
        <div className={styles.cp_right_box}>
          {/* <div className={styles.cp_dateblk}>
                <span>Last Synced : </span>
                <span>{syncDate}</span> 
          </div>  */}
          <div className={styles.cp_newsizeblk}>
            <p>New Size </p>
            <p className={styles.actionBlk}>
              <p onClick={() => {
                setModalHeader('Add New Size');
                setAddModalStatus(true);
              }}>
                <FaPlusSquare size={20} />
              </p>
            </p>
          </div>
          <div className={styles.configBrdr}></div>
          <div>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setIsSyncLogPopup(true)}
            >
              Sync
            </Button>
          </div>
          {/* <div className={styles.configBrdr}></div>
          <div>
              <Button
                variant="contained"
                color="primary"
                onClick={syncODCentralized}
                disabled={loaderStatus ? true : false} // Disable button while loading
                startIcon={loaderStatus ? <CircularProgress size={20} color="inherit" /> : null} // Show loader icon
                sx={{
                  backgroundColor: loaderStatus ? "gray" : "primary.main", // Gray when disabled
                  "&:disabled": {
                    backgroundColor: "gray", // Ensure it remains gray when disabled
                    color: "white",
                  },
                }}
              >
                {loaderStatus ? "Syncing in progress..." : "Sync"}
              </Button>
          </div> */}
        </div>
      </Box>

      {selectTab &&
        <CustomTabPanel value={value} index={value} >
          <TableData
            ProdType={selectTab?.MachineType}
            MCTypeKey={selectTab?.MachineTypeKey}
            AddModalStatus={AddModalStatus}
          />
          <>
            {AddModalStatus ?

              <AddModal
                AddModalStatus={AddModalStatus}
                ModalHeader={ModalHeader}
                setAddModalStatus={setAddModalStatus}
                MCTypeKey={selectTab?.MachineTypeKey}
                From={'CapsuleParameter'}
              />
              : null
            }
          </>
        </CustomTabPanel>
      }
      { isSyncLogPopup &&
        <Modal
          open={isSyncLogPopup}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{...updatesStyle, width: 'calc(70vw)' }}>
          <div className={styles.cpClsIcon}>
            <CloseIcon
              onClick={() => setIsSyncLogPopup(false)}
              size={18}
            />
          </div>
            <SyncLogPopup />
          </Box>
        </Modal>
      }
    </Box>
  );
}
