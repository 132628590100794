/* eslint-disable */
import { useState } from 'react';
import PropTypes from 'prop-types';

import {
  //   Modal,
  Box, Tabs, Tab,
  Typography,
  // Button, CircularProgress,
} from '@mui/material';
import SyncTable from './Sync';
import SyncLogTable from './SyncLog';
import AuditLogTable from './AuditSyncLog';

function CustomTabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function SyncLogPopup() {
  const tabsInfo = ['Sync', 'Sync Log', 'Audit Log'];
  const [value, setValue] = useState(0);
  const [, setTab] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setTab(newValue);
  };
  const renderComponent = (value) => {
    let obj = {
      0: <SyncTable />,
      1: <SyncLogTable />,
      2: <AuditLogTable />
    }
    return obj[value] ? obj[value] : null
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ ml: 1 }}>
        <div>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {tabsInfo.map((label, index) => (
              <Tab
                key={index}
                label={label}
                {...a11yProps(index)} // Assuming a11yProps is defined elsewhere
                // className={styles.tabs_head}
                sx={{
                  color: '#E0E0E0',
                  textTransform: 'none',
                  fontWeight: 600,
                  fontSize: '13px',
                  fontFamily: 'Montserrat-Medium'
                }}
              />
            ))}
          </Tabs>
        </div>
      </Box>

      <CustomTabPanel value={value} index={value} >
        {renderComponent(value)}
      </CustomTabPanel>

    </Box>
  );
}
