/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import styles from './index.module.css';
import "./index.css";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Box,
    CircularProgress,
} from '@mui/material';
import getAuditLogs from '../../../../API/CentralizedCapsuleApis/getAuditLog';

const AuditLogTable = () => {
    const [auditLog, setAuditLog] = useState([]);
    const [loading, setLoading] = useState(false); // Loading state
    const [itemsCountPerPage] = useState(10);
    const [activePage, setActivePage] = useState(1);
    const [pageRangeDisplayed] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const lastData = activePage * itemsCountPerPage;
    const firstData = lastData - itemsCountPerPage;

    const getAuditLogList = async () => {
        setLoading(true);
        const res = await getAuditLogs();
        if (res && res.length > 0) {
            setAuditLog(res);
            setTotalData(res.length);
        } else {
            setAuditLog([]);
        }
        setLoading(false); // Hide loader
    };
    useEffect(() => {
        getAuditLogList();
    }, []);

    return (
        <Box sx={{ width: '98%', margin: 'auto', mt: 2, ml: 1 }}>
            {loading ? ( // Show loader while loading
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                    <CircularProgress color="primary" />
                </div>
            ) : (
                <>
                    <div className={styles.cal_cntnerBlk}>
                        <TableContainer className={styles.cal_TableCntainer} component={Paper}>
                            <Table sx={{ border: '1px solid #4f4f4f' }}>
                                <TableHead className={styles.cal_TableHead}>
                                    <TableRow sx={{ backgroundColor: '#2f2f2f' }}>
                                        <TableCell className={styles.cal_cell}>Size/EC</TableCell>
                                        <TableCell align="center" className={styles.cal_cell}>Weight Type</TableCell>
                                        <TableCell align="center" className={styles.cal_cell}>Lock Type</TableCell>
                                        <TableCell align="center" className={styles.cal_cell}>Parameter Name</TableCell>
                                        <TableCell align="center" className={styles.cal_cell}>Value Name</TableCell>
                                        <TableCell align="center" className={styles.cal_cell}>Old Value</TableCell>
                                        <TableCell align="center" className={styles.cal_cell}>New Value</TableCell>
                                        <TableCell align="center" className={styles.cal_cell}>Modified Date & Time</TableCell>
                                        <TableCell align="center" className={styles.cal_cell}>User</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={styles.calTbody}>
                                    {(auditLog && auditLog.length > 0) ?
                                        (auditLog || []).slice(firstData, lastData).map((item, index) => (
                                            <TableRow key={`${index}-${item['Size/EC']}`} sx={{ backgroundColor: '#1f1f1f', color: '#fff' }}>
                                                <TableCell className={styles.cal_cell}>{item?.['Size/EC'] ? item?.['Size/EC'] : 'N/A'}</TableCell>
                                                <TableCell align="center" className={styles.cal_cell}>{item?.WeightType ? item?.WeightType : 'N/A'}</TableCell>
                                                <TableCell align="center" className={styles.cal_cell}>{item?.LockType ? item?.LockType : 'N/A'}</TableCell>
                                                <TableCell align="center" className={styles.cal_cell}>{item?.ParameterName ? item?.ParameterName : 'N/A'}</TableCell>
                                                <TableCell align="center" className={styles.cal_cell}>{item?.ValueName ? item?.ValueName : 'N/A'}</TableCell>
                                                <TableCell align="center" className={styles.cal_cell}>{item?.OldValue ? item?.OldValue : 'N/A'}</TableCell>
                                                <TableCell align="center" className={styles.cal_cell}>{item?.NewValue ? item?.NewValue : 'N/A'}</TableCell>
                                                <TableCell align="center" className={styles.cal_cell}>{item?.DateTimestamp ? item?.DateTimestamp : 'N/A'}</TableCell>
                                                <TableCell align="center" className={styles.cal_cell}>{item?.User ? item?.User : 'N/A'}</TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        <TableRow className="header">
                                            <TableCell style={{ textAlign: "center" }} align="center" scope="row" colSpan={9} className="table-data"> No Data Available</TableCell>
                                        </TableRow>
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div
                        className={styles.cal_logPgnation}
                        style={{
                            height: "50px",
                            marginTop: "0px",
                        }}
                    >
                        {totalData >= itemsCountPerPage ? (
                            <Pagination
                                activePage={activePage}
                                itemsCountPerPage={itemsCountPerPage}
                                totalItemsCount={totalData}
                                pageRangeDisplayed={pageRangeDisplayed}
                                onChange={(currentPage) => setActivePage(currentPage)}
                            />
                        ) : (
                            " "
                        )}
                    </div>
                </>
            )}
        </Box>

    );
};

export default AuditLogTable;
