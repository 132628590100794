/* eslint-disable */
import React, { useState, useEffect, useRef } from 'react';
import { message } from 'antd';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import { setSyncLoadingStatus } from '../../../../redux/slices/odCentralizedSlice';
import styles from './index.module.css';
import getSyncJobStatus from "../../../../API/CentralizedCapsuleApis/getSyncJobStatus";
import getSyncData from '../../../../API/CentralizedCapsuleApis/getLastSyncData';
import syncCentralizedOD from "../../../../API/CentralizedCapsuleApis/syncCentralizedOD";
import { useDispatch, useSelector } from 'react-redux';

const SyncTable = () => {
  const dispatch = useDispatch();
  const syncLoadingStatus = useSelector((state) => state?.centralizedOD?.SYNCAPI_LOADING_STATUS);
  const [data, setData] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  const getLastSyncData = async () => {
    // Only set loading state if no dependencies (i.e., syncLoadingStatus is empty)
    if (Object.keys(syncLoadingStatus).length === 0) {
      setIsloading(true);
    }
  
    const res = await getSyncData(); // Assume getSyncData() returns the data
    if (res && res.length > 0) {
      setData(res);
  
      // Update sync loading status for plants with ongoing sync
      if (Object.keys(syncLoadingStatus).length > 0) {
        const updatedSyncStatus = { ...syncLoadingStatus }; // Clone syncLoadingStatus
  
        res.forEach((item) => {
          console.log('Checking item:', item); // Log each item from res
          const plantCode = item?.PlantCode;
  
          // Check if this PlantCode exists in syncLoadingStatus
          if (updatedSyncStatus[plantCode]) {
            // If CurrentSyncStatus is not defined or success, delete from syncLoadingStatus
            if (!item?.CurrentSyncStatus || (item?.CurrentSyncStatus || '').toLowerCase().includes('success')) {
              message.success(`Sync completed successfully for ${item?.Plant}`)
              delete updatedSyncStatus[plantCode];
            }
          }
        });
  
        // After loop, log updated sync status
        console.log('Updated sync loading status:', updatedSyncStatus);
  
        // Only dispatch if the state has changed
        if (Object.keys(updatedSyncStatus).length !== Object.keys(syncLoadingStatus).length) {
          dispatch(setSyncLoadingStatus(updatedSyncStatus)); // Dispatch updated status
        } else {
          console.log('No change in syncLoadingStatus, not dispatching.');
        }
      }
    } else {
      setData([]); // If no data, clear the data state
    }
  
    setIsloading(false); // End loading
  };
  

  // UseEffect to trigger initial data fetch on mount
  useEffect(() => {
    getLastSyncData();
  }, []);

  // Polling interval effect
  useEffect(() => {
    console.log('syncLoadingStatus', syncLoadingStatus);

    // Only set an interval if there are dependencies in syncLoadingStatus
    if (Object.keys(syncLoadingStatus).length === 0) return;

    const pollingInterval = setInterval(async () => {
      await getLastSyncData();
    }, 30000); // Poll every 60 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(pollingInterval);
  }, [syncLoadingStatus]); // Re-run when syncLoadingStatus changes

  const handleSync = async (plant) => {
    console.log('plantId', plant);
    try {
      const plantCode = plant?.PlantCode;
      const res = await syncCentralizedOD(plant?.Id);
      await getLastSyncData();
      if (res?.status === 1) {
        dispatch(setSyncLoadingStatus({
          ...syncLoadingStatus,
          [plantCode]: true
        }));
      } else {
        dispatch(setSyncLoadingStatus({
          ...syncLoadingStatus,
          [plantCode]: false
        }));
      }
    } catch (err) {
      message.error(err?.message);
      console.log('err', err);
      dispatch(setSyncLoadingStatus({
        ...syncLoadingStatus,
        [plantCode]: false
      }));
    }
  }

  return (
    <Box sx={{
      width: '98%',
      margin: 'auto',
      mt: 2,
      ml: 1,
    }}
    >
      <TableContainer component={Paper}>
        <Table sx={{ border: '1px solid #4f4f4f' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#2f2f2f' }}>
              <TableCell className={styles.syncCell}><strong>Plant</strong></TableCell>
              <TableCell align="center" className={styles.syncCell}><strong>Sync with Edge Status</strong></TableCell>
              <TableCell align="center" className={styles.syncCell}><strong>Last Synced</strong></TableCell>
              {/* <TableCell align="center" className={styles.syncCell}><strong>Status</strong></TableCell> */}
              <TableCell align="center" className={styles.syncCell}><strong>Action</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow sx={{ backgroundColor: '#1f1f1f', color: '#fff', border: 'none' }}>
                <TableCell align="center" className={styles.syncCell} colSpan={5}>
                  <CircularProgress size={22} />
                </TableCell>
              </TableRow>
            ) : (
              data && data.length > 0 ? (
                data.map((plant) => (
                  <TableRow key={plant.id} sx={{ backgroundColor: '#1f1f1f', color: '#fff', border: 'none' }}>
                    <TableCell className={styles.syncCell}>{plant.Plant}</TableCell>
                    <TableCell align="center" className={styles.syncCell}>
                      <Typography display="inline">{plant?.CurrentSyncStatus ?? 'N/A'}</Typography>
                    </TableCell>
                    <TableCell align="center" className={styles.syncCell}>{plant?.LastSynced ?? 'N/A'}</TableCell>
                    <TableCell align="center" className={styles.syncCell}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSync(plant)}
                        disabled={syncLoadingStatus?.[plant.PlantCode]} // Disable button while loading
                        startIcon={syncLoadingStatus?.[plant.PlantCode] ? <CircularProgress size={20} color="inherit" /> : null} // Show loader icon
                        sx={{
                          backgroundColor: syncLoadingStatus?.[plant.PlantCode] ? "gray" : "primary.main", // Gray when disabled
                          "&:disabled": {
                            backgroundColor: "gray", // Ensure it remains gray when disabled
                            color: "white",
                          },
                        }}
                      >
                        {syncLoadingStatus?.[plant.PlantCode] ? "Syncing in progress..." : "Sync"}
                      </Button>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ backgroundColor: '#1f1f1f', color: '#fff', border: 'none' }}>
                  <TableCell align="center" className={styles.syncCell} colSpan={5}>No data found</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* } */}
    </Box>
  );
};

export default SyncTable;
