import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // SYNCAPI_LOADING_STATUS: false,
  // SYNCAPI_JOBRUN_ID: '',
  SYNCAPI_LOADING_STATUS: {},
};

export const centralizedOD = createSlice({
  name: 'CentralizedOD',
  initialState,
  reducers: {
    setSyncLoadingStatus: (state, action) => {
      state.SYNCAPI_LOADING_STATUS = action.payload;
    },
    setSyncJobRunId: (state, action) => {
      state.SYNCAPI_JOBRUN_ID = action.payload;
    },
  },
});

export const { setSyncLoadingStatus, setSyncJobRunId } = centralizedOD.actions;

export default centralizedOD.reducer;
