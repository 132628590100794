/* eslint-disable */
import React, { useState, useEffect } from 'react';
import Pagination from "react-js-pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  CircularProgress,
} from '@mui/material';
import styles from './index.module.css';
import getSyncLogs from '../../../../API/CentralizedCapsuleApis/getSyncLog';

const SyncLogTable = () => {
  const [syncLogData, setSyncLogData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [itemsCountPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [pageRangeDisplayed] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const lastData = activePage * itemsCountPerPage;
  const firstData = lastData - itemsCountPerPage;

  const getSyncLogsList = async () => {
    setIsLoading(true);
    const res = await getSyncLogs();
    if (res && res.length > 0) {
      setSyncLogData(res);
      setTotalData(res.length);
    } else {
      setSyncLogData([]);
    }
    setIsLoading(false);
  }
  useEffect(() => {
    getSyncLogsList()
  }, []);

  return (
    <Box sx={{ width: '98%', margin: 'auto', mt: 2, ml: 1 }}>
      {isLoading ? ( // Show loader while loading
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress color="primary" />
        </div>
      ) : (
        <>
        <div className={styles.csy_cntnerBlk}> 
          <TableContainer 
          className={styles.csy_TableCntainer}>
            <Table sx={{ border: '1px solid #4f4f4f' }}>
              <TableHead className={styles.csy_TableHead}>
                <TableRow sx={{ backgroundColor: '#2f2f2f' }}>
                  <TableCell className={styles.csy_cell}>Plant</TableCell>
                  <TableCell align="center" className={styles.csy_cell}>Last Synced</TableCell>
                  <TableCell align="center" className={styles.csy_cell}>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(syncLogData && syncLogData.length > 0) ?
                  (syncLogData || []).slice(firstData, lastData).map((plant) => (
                    <TableRow key={plant.id} sx={{ backgroundColor: '#1f1f1f', color: '#fff', border: 'none' }}>
                      <TableCell className={styles.csy_cell}>{plant?.plant ?? 'N/A'}</TableCell>
                      <TableCell align="center" className={styles.csy_cell}>{plant?.lastSynced ?? 'N/A'}</TableCell>
                      <TableCell align="center" className={styles.csy_cell}>
                          <Typography>{plant?.status ?? 'N/A'}</Typography>
                      </TableCell>
                    </TableRow>
                  ))
                  :
                  <TableRow className="header">
                    <TableCell style={{ textAlign: "center" }} align="center" scope="row" colSpan={3} className="table-data"> No Data Available</TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </TableContainer>
          </div>
          <div
            className={styles.cal_logPgnation}
            style={{
              height: "50px",
              marginTop: "0px",
            }}
          >
            {totalData >= itemsCountPerPage ? (
              <Pagination
                activePage={activePage}
                itemsCountPerPage={itemsCountPerPage}
                totalItemsCount={totalData}
                pageRangeDisplayed={pageRangeDisplayed}
                onChange={(currentPage) => setActivePage(currentPage)}
              />
            ) : (
              " "
            )}
          </div>
        
        </>
      )}
    </Box>

  );
};

export default SyncLogTable;
